import React, { useEffect, useState } from "react"
import P from "../../core/P"

export const CareerBlock = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    async function dataFetch() {
      const url = "https://api.webmobtech.com/wmtblog/api/web/current_opening"

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cndId: 0,
          startingAt: 0,
          orderBy: "jobPriority",
          orderType: "DESC/ASC",
        }),
      }
      const response = await fetch(url, requestOptions)
      const data = await response.json()
      setData(data)
    }

    dataFetch()
  }, [])
  // console.log("data", data)
  return (
    <div className="flex justify-center bg-blue-200">
      <div className="px-6 md:px-12 lg:px-28 w-screen">
        <div
          className={"grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6"}
          data-aos="fade-up"
        >
          {data.length > 0
            ? data.map(e => (
                <div
                  className={
                    "bg-white rounded-md hover:shadow-xl hover:-translate-y-1"
                  }
                  key={Math.random()}
                >
                  <a href={e.applyUrl} target="_blank" rel="noreferrer">
                    <div className="relative lg:mx-10 lg:my-8 md:m-7 m-6 h-56">
                      <div>
                        <div className="font-serif font-semibold lg:text-2xl text-xl tracking-normal leading-7 text-blue-500 ">
                          {e.jobTitle}
                        </div>
                        <hr className="my-4 border-green-500 bg-green-500  border-2 w-24" />
                        <P
                          title={`${e?.numberOfOpening}&nbsp;
                            ${e?.numberOfOpening > 1 ? "Openings" : "Opening"}`}
                          noPad
                          className="font-serif font-medium text-shark-500"
                        />
                        <P
                          title={`Exp: ${e?.minWorkExp} to ${e?.maxWorkExp}
                              ${e?.minWorkExp > 0 ? "years" : "year"}`}
                          noPad
                          className="font-serif font-medium text-shark-200"
                        />
                      </div>
                      <div className="absolute bottom-0 left-0 ">
                        <a
                          href={e.applyUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="bg-blue-500 inline-flex relative px-6 py-2 text-base leading-6 font-medium text-white  hover:bg-blue-600 rounded"
                        >
                          Apply
                        </a>
                      </div>
                    </div>
                  </a>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  )
}

export default CareerBlock
